.home-footer {
    padding-top: 3%;
    padding-bottom: 1%;

    .common-footer-about {
        padding-bottom: 20px;

        h3 {
            font-size: 36px;
            letter-spacing: .3px;
            font-weight: 700;
            color: #fff;
            line-height: 1.3;
            margin-bottom: 20px;
        }

        p {
            letter-spacing: .5px;
            font-feature-settings: "salt" on, "liga" off;
            color: #b4b3b3;
            margin: 0;
            padding-bottom: 15px;
        }

    }
}