@keyframes s2 {
  to {
    transform: rotate(1turn);
  }
}

.spinner-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 999;
  top: 0;
  left: 0;
}
.loading-spinner-outer {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 50px;
}

.loading-spinner {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid lightblue;
  border-right-color: #034d82;
  animation: s2 1s infinite linear;
}

.spinner-container.login-waiting-loader {
  position: static;
  overflow-x: hidden;
}
.jobs-main {
  position: relative;
  min-height: 800px;
  .spinner-container {
    position: relative;
    left: unset;
    top: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    height: unset;
    width: unset;
    min-height: 400px;
    .loading-spinner-outer {
      transform: none;
      position: relative;
      left: unset;
      top: unset;
    }
  }
}
// detail page banner //
.banner-section {
  position: relative;
  .banner-img {
    position: relative;
  
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
    img {
      display: block;
      width: 100%;
      @media only screen and (max-width: 768px) {
        min-height: 160px;
        object-fit: cover;
    }
    }
    .video {
      width: 100% !important;
      height: 638px !important;
    }
  }
  .banner-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100px;
    padding: 0 80px;
    @media only screen and (max-width: 1024px) {
      bottom: 10px;
      
    }
    @media only screen and (max-width: 780px) {
      padding: 0 40px;
      bottom: 10px;
    }
    @media only screen and (max-width: 480px) {
      padding: 0 40px;
      
    }
    .banner-text {
      display: inline-block;
      padding: 15px 0;
      border-radius: 8px;
      max-width: 700px;
      position: relative;
      @media only screen and (max-width: 480px) {
        width: 100%;
        max-width: 100%;
      }
      h2 {
        font-size: 40px;
        line-height: 48px;
        font-weight: bold;
        color: #fff;
        position: relative;
        @media only screen and (max-width: 780px) {
          font-size: 28px;
        }
        @media only screen and (max-width: 480px) {
          font-size: 22px;
          line-height: 1;
        }
      }
      p {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        color: #fff;
        padding-top: 3px;
        position: relative;
        @media only screen and (max-width: 480px) {
          font-size: 14px;
        }
      }
    }
  }
}

// Global input style
input {
  font-size: 12px;

  &::placeholder {
    color: black;
  }

  & ::-ms-input-placeholder {
    color: black;
  }

  &:focus-visible {
    outline: none;
  }
}
input[type="checkbox"] {
  height: 16px;
  width: 16px;
}

.blog-checkbox {
  input[type="checkbox"] {
    &:checked {
      + {
        label {
          color: #034d82;
        }
      }
    }
  }
}

// job listing //
.experience {
  &::before {
    content: "";
    background: url(../images/experience.png) no-repeat;
  }
}
.location {
  &::before {
    content: "";
    background: url(../images/location.png) no-repeat;
  }
}

//job listing filters //
.job-filter {
  .filter-button {
    position: relative;
    display: inline-block;
  }
  &:hover .form-radios {
    display: block;
  }
  .form-radios {
    display: none;
    position: absolute;
    padding: 0.5em 1.5em;
    border: 1px solid #90a3b4;
    font-size: 18px;
    width: 537px;
    background-color: #fff;
    z-index: 1;
    @media only screen and (max-width: 780px) {
      width: 210px;
      padding: 0.5em 0.5em;
    }
    @media only screen and (max-width: 480px) {
      width: 310px;
    }
    ul {
      li {
        margin-right: 0.5em;
        font-size: 16px;
        font-weight: 600;
        color: #646464;
        margin-top: 0.4em;
        margin-bottom: 0.4em;
        line-height: 30px;
        @media only screen and (max-width: 780px) {
          font-size: 13px;
        }
        label {
          display: flex;
          align-items: center;
          text-align: center;
        }
        input {
          margin-right: 0.5em;
        }
      }
    }
  }
}

// job detail //
.job-items-detail {
  ul {
    list-style: disc;
    margin-left: 20px;
    li {
      margin-bottom: 10px;
      color: #4f4f4f;
      font-size: 18px;
    }
  }
  p {
    margin-bottom: 10px;
    color: #4f4f4f;
    font-size: 18px;
    letter-spacing: 0.5px;
  }
}

//table//
.technologies-table {
  @media only screen and (max-width: 600px) {
    overflow-x: auto;
  }
}
table {
  background: #ededed;
  width: 100%;
  border: none;
  border-collapse: separate;
  border-spacing: 0;
  caption {
    margin-bottom: 10px;
    text-align: center;
    font-weight: 600;
  }
  tr,
  td,
  th {
    font: 500 14px/21px;
    color: #221e20;
    border: none;
    @media only screen and (max-width: 600px) {
      font-size: 14px;
    }
  }
  tr {
    th {
      font: 700 14px/21px;
      color: #fff;
      padding: 10px 20px;
      text-align: center;
      background-color: #034d82;
      border-right: 1px solid #ededed;
    }
    td {
      padding: 10px 20px;
      background-color: #fff;
      text-align: left;
      border-right: 1px solid #e5e6f0;
      border-bottom: 1px solid #e5e6f0;
      &:first-child {
        border-left: 1px solid #e5e6f0;
      }
    }
  }
}

// blog detail//
.blog-arrow {
  &::before {
    content: "";
    background: url(../images/leftarrow.png) no-repeat;
  }
}
.load-more-btn {
  background: linear-gradient(
    94.03deg,
    #0f5c74 -123.48%,
    #0f5c74 -0.15%,
    #000345 291.56%
  );
}

// Comment section //

.comment,
.comment-reply {
  position: relative;
  &::before {
    position: absolute;
    top: 20px;
    right: 100%;
    content: "";
    border-top: 20px solid transparent;
    border-right: 20px solid rgb(243 244 246 / var(--tw-bg-opacity));
    border-bottom: 20px solid transparent;
  }
  .comment-reply {
    &::before {
      top: 17px;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 10px solid rgb(203 213 225 / var(--tw-bg-opacity));
    }
  }
}
.antialiased {
  > .comment-sec-reply {
    &::before {
      content: none;
    }
  }
}
.comment-post-btn,
.save-post-btn,
.category-btn,
.check-btn {
  background: linear-gradient(
    94.03deg,
    #0f5c74 -123.48%,
    #0f5c74 -0.15%,
    #000345 291.56%
  );
}
.comment {
  &.bg-slate-300 {
    &::before {
      border-right-color: rgb(203, 213, 225) !important;
    }
  }
}

.comment-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1260;
  padding: 0;
  border: none !important;
  background: #fff;
  max-height: 100%;
  width: 46%;
  border-radius: 1rem;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  @media only screen and (max-width: 480px) {
    width: 92%;
  }
  @media only screen and (max-width: 820px) {
    width: 90%;
  }
  .comment-popup-container {
    textarea {
      width: 100%;
      height: 250px;
      resize: vertical;
      padding: 20px;
      border-radius: 1rem;
      background-color: #ededed;
    }
    textarea:focus,
    .focus-visible:focus:not(:focus-visible) {
      outline: 0;
    }
  }
}
.comment-popup:focus,
.focus-visible:focus:not(:focus-visible) {
  outline: 0;
  border: none;
}

// Forgot password //

.forgot-password,
.change-password {
  .form {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    .heading {
      border-bottom-width: 1px;
      border-bottom-color: rgba(0, 0, 0, 0.12);
      border-bottom-style: solid;
    }
  }
}

.user-profile-sliders {
  .user-blog-slider {
    .slick-next {
      right: -15px;
    }
    .slick-prev:before,
    .slick-next:before {
      color: #004282;
      font-size: 32px;
    }
    &:empty {
      max-width: 100%;
    }
  }
  .user-job-slider {
    .slick-next {
      right: -15px;
    }
    .slick-prev:before,
    .slick-next:before {
      color: #004282;
      font-size: 32px;
    }
    .slick-slide {
      margin-bottom: 20px;
    }

    &:empty {
      max-width: 100%;
    }
  }
}

.profile-heading {
  min-width: 135px;
  text-align: right;
}

.user-job-slider{
  .slick-track {
    margin: unset;
  }
}

.user-blog-slider {
  .slick-track {
    display: flex !important;
    justify-content: center;
  }

  .slick-slide {
    height: 100% !important;
  }
}
.search__bar {
  border-bottom: 0.1rem solid #000;
  margin: 0 0 1.7rem;
  padding-bottom: 0.6rem;
  position: relative;
  width: 100%;
  .search__term {
    background: none;
    border: 0;
    color: #000;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1;
    outline: none;
    padding: 0;
    text-transform: capitalize;
    width: calc(100% - 5.4rem);
    &::placeholder {
      color: gray;
      opacity: 1; /* Firefox */
    }
  }
  .search__submit {
    background: url(../images/right-arrow.png) no-repeat 100%;
    border: 0;
    height: 1.9rem;
    margin-top: 0.5rem;
    outline: none;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 50%;
    transform: translateY(-50%);
    width: 5.4rem;
  }
}

.animated-list > li {
  position: relative;
  @media only screen and (max-width: 1100px) and (min-width: 1023px) {
    margin-left: 20px !important;
  }
}
.animated-list > li > a {
  position: relative;
}
.animated-list > li > a::after {
  content: "";
  position: absolute;
  bottom: -18px;
  left: 0;
  width: 0;
  height: 4px;
  background-color: #fff;
  transition: width 0.3s ease;
  @media only screen and (max-width: 1023px) {
    display: none;
  }
}
.animated-list > li > a.active::after {
  width: 100%;
}
.animated-list > li > a:hover::after {
  width: 100%;
}
.animated-list .login-info::after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  background-color: #fff;
  transition: width 0.3s ease;
  bottom: 0;
  height: 4px;
}
.animated-list > .login-info:hover::after {
  width: 100%;
}
.animated-list .language-dropdown-container::after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  background-color: #fff;
  transition: width 0.3s ease;
  bottom: 0;
  height: 4px;
  @media only screen and (max-width: 480px) {
    display: none;
  }
}
.animated-list > .language-dropdown-container:hover::after {
  width: 100%;
}
.animated-list > .language-dropdown-container a::after {
  content: none;
}
.animated-list .state-list-container::after {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  background-color: #fff;
  transition: width 0.3s ease;
  bottom: -22px;
  height: 4px;
  @media only screen and (max-width: 480px) {
    display: none;
  }
}
.animated-list > .state-list-container:hover::after {
  width: 100%;
}

.state-list-container:focus-within {
  svg {
    transform: rotate(180deg);
  }
}
.login-info {
  svg {
    fill: white !important;
  }
  &:hover {
    svg {
      transform: rotate(180deg);
    }
  }
}
.category-details {
  box-shadow: 1px 6px 8px 1px lightgrey;
  border: 1px solid lightgrey;
}

.state-list-container [class$="menu"] {
  top: 133% !important;
  right: 0% !important;
  border-radius: 0 !important;
  border-bottom-left-radius: 4px !important;
}
.state-list-container [class$="control"] {
  border: none;
  box-shadow: none;
}
.state-list-container [class$="control"]:hover {
  border: none;
  box-shadow: none;
  cursor: pointer;
}
.state-list-container [class$="options"]:focus {
  border: none;
  box-shadow: none;
  cursor: pointer;
}
.state-list-container [class$="options"]:focus-visible {
  .state-list-container [class$="control"] {
    border: none;
    box-shadow: none;
  }
}
.state-list-container{
 @media screen  and (min-width:1023px) and (max-width:1100px){
  max-width: 155px;
 }
}
// Content Authentication ui //

.content-auth {
  //     position: fixed;
  //     width: 100%;
  //     height: 100%;
  //     top: 88px;
  //     left: 0px;
  //     display: flex;
  //     flex-direction: column;
  //     -webkit-box-pack: center;
  //     justify-content: center;
  //     -webkit-box-align: center;
  //     align-items: center;
  //     cursor: pointer;
  //     animation: 0.3s ease-in 0s 1 normal none running xBAQB;
  //     word-break: break-word;
  //     // background-color: rgba(255, 255, 255, 0.7);
  //     backdrop-filter: blur(3px);
  //     background-color: rgba(210, 216, 214, 0.7);
  //     // background-color: rgba(15, 92, 116, 0.40);
  //     z-index: 200000;
  .btn {
    // font-weight: 500;
    background: linear-gradient(
      94.03deg,
      #0f5c74 -123.48%,
      #0f5c74 -0.15%,
      #000345 291.56%
    );
  }
}
// .auth-card{
//     --bg-color: #148bb1;
//     --bg-color-light: #187896;
//     --text-color-hover: #fff;
//     --box-shadow-color: rgba(15, 92, 116, 0.40);
//     width: 520px;
//     height: 400px;
//     background: #fff;
//     // background-color: #e9e9e9;
//     // background-image: linear-gradient(147deg, #c3cbdc 0%, #edf1f4 74%);
//     border-radius: 10px;
//     overflow: hidden;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     position: relative;
//     box-shadow: 0 14px 26px rgba(0, 0, 0, 0.04);
//     transition: all 0.3s ease-out;
//     text-decoration: none;
//     &:hover{
//         transform: translateY(-5px) scale(1.005) translateZ(0);
//         box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
//           0 24px 46px var(--box-shadow-color);
//           .overlay{
//             transform: scale(14) translateZ(0);
//           }
//           .circle{
//             border-color: var(--bg-color-light);
//             background: var(--bg-color);
//           }
//           p{
//             color: var(--text-color-hover);
//           }
//     }
//     p{
//         color: #4c5656;
//         z-index: 1000;
//         transition: color 0.3s ease-out;
//     }
// }
// .overlay{
//     width: 110px;
//     position: absolute;
//     height: 50px;
//     border-radius: 14px;
//     // background: var(--bg-color);
//     background: linear-gradient(94.03deg, #0f5c74 -123.48%, #0f5c74 -0.15%, #000345 291.56%);
//     top: 52%;
//     left: 49%;
//     transform: translate(-49px, 0px);
//     z-index: 0;
//     transition: transform 0.3s ease-out;
// }

// jobs listing page ui //

.jobs-main {
  input::placeholder {
    color: #a1a1a1;
  }
}

// tabs //
.react-tabs{
  padding: 10px;
.react-tabs__tab-list {
  border-bottom: 1px solid #004282 !important;
  margin-bottom: 40px;
  @media only screen and (max-width: 480px) {
    overflow-x: auto;
    display: flex;
    white-space: nowrap;
}
  .react-tabs__tab {
    padding: 12px 16px;
    margin-right: 10px;
    border-radius: 5px 5px 0 0;
    bottom: 0;
    border-color: #004282;
    background: #fff;
    color: #004282;
  }

  .react-tabs__tab--selected {
    color: #fff !important;
    background: linear-gradient(122.65deg,#0f5c74 -24.49%,rgba(0, 3, 69, 0.99) 136.4%) !important;
    border-color: #004282 !important;
    &::after {
      background-color: transparent !important;
    }
  }
}
}
.auth-card.box-shadow{
  box-shadow: 0px 0px 5px #dbdbdb;
  &::before{
    content: " ";
    position: absolute;
    top: -54px;
    height: 30%;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5), white);
    @media only screen and (max-width: 768px) {
      top: -70px;
    }
    @media only screen and (max-width: 480px) {
      top: -105px;
    }
  }
}
.navigation-buttons{
  .category-btn{
    padding: 1rem 3rem;
    border-radius: 0.25rem;}}
.like-block{
  .relative{
    min-width: 70px;
  }
}
.position-postbtn{
    position: sticky;
    bottom: 30px;
    width: calc(45% + 250px);
    left: 0;
  }