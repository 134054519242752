.list {
    border: 1px solid #fff;
    padding: 5px;
  }
  
  .pagination {
    display: flex;
    border: 1px solid #fff;
    justify-content: center;
    align-items: center;
    padding: 20px;
    .active {
      background: #0f5c74;
      color: #fff;
      // border: 1px solid #fff;
    }
    button{

    }
  }