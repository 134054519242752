
.case-studies{
    .portfolio-item-container:nth-child(even){
        background-color:#f6fbff;
       }
}

.readcase-button{
    background: linear-gradient(94.03deg,#0f5c74 -123.48%,#0f5c74 -.15%,#000345 291.56%);
    position: relative;
    &::before{
        content: "";
        position: absolute;
        width: 32px;
        left: 168px;
        height: 32px;
        bottom: 8px;
        background: url(../images/arrowbtn.png);
        background-repeat: no-repeat;
        background-position: 0;
        font-size:8px !important;
    }
}

.portfolio-content h3{
    font-size: 24px;
    line-height: 31px;
    letter-spacing: -.807147px;
    font-weight:700;
    color: #034d82;
    margin-bottom: 25px;
    margin-top: 40px;
}

// Portfolio category page //

.portfolio-cat{
    border-radius: 1rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}


//portfolio-listing-layout
.case-studies.left{
    .portfolio-item-container{
        .portfolio-item-image{
            order: 1;
            flex: 1 1 42%;
           
        }
        .portfolio-item-text{
            order:2;
            flex: 1 1 50%;
        }
        &:nth-child(2n){
            .portfolio-item-image{
                order: 2;
                flex: 1 1 42%;
               
            }
            .portfolio-item-text{
                order:1;
                flex: 1 1 50%;
            }
        }

    }
}
.case-studies.right{
    .portfolio-item-container{
        .portfolio-item-image{
            order: 2;
            flex: 1 1 42%;
            
        }
        .portfolio-item-text{
            order:1;
            flex: 1 1 50%;
        }
        &:nth-child(2n){
            .portfolio-item-image{
                order: 1;
                flex: 1 1 42%;
                
            }
            .portfolio-item-text{
                order:2;
                flex: 1 1 50%;
            }
        }
    }
    
}
.case-studies.grid{
    padding:0 5rem;
    @media only screen and (min-width: 320px) {
        padding:0;
        }
}



.portfolio-btn-container{
    padding: 0px 80px;
    @media only screen and (max-width: 768px) {
        padding: 0 10px;}
    .right-btn {
            background-image: url(../images/css_sprites.png);
            background-position: -124px -12px;
            background-repeat: no-repeat;
            height: 33px;
            width: 36px;
            &.active {
                background-image: url(../images/css_sprites.png);
                background-position: -124px -65px;
            }
    
    
        }
    .left-btn{
        background-image: url(../images/css_sprites.png);
        background-position: -9px -68px;
        background-repeat: no-repeat;
        height: 33px;
        width: 36px;
        &.active{
            background-image: url(../images/css_sprites.png);
            background-position: -65px -68px;
           
        }
        
       
    }
    .grid-btn{
        background-image: url(../images/css_sprites.png);
        background-repeat: no-repeat;
        background-position: -10px -12px;
        height: 36px;
        width: 36px;
        &.active{
            background-image: url(../images/css_sprites.png);
            background-position: -66px -12px;
           
        }
        
        
    }
}


.grid-readcase-button{
    background: white;
}


.flip-card {
    background-color: transparent;
    width: 100%;
    height: 400px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #bbb;
    color: black;
    .portfolio-overlay{
        position:absolute;
        left:0;
        top:0;
        background: rgba(0,0,0,.4);
        width:100%;
        height:100%;
    }
    
  }
  
  .flip-card-back {
    background: linear-gradient(94.03deg,#0f5c74 -123.48%,#0f5c74 -.15%,#000345 291.56%);
    color: white;
    transform: rotateY(180deg);
  }


  .grid-inner-element:hover  .grid-hidden-container{
    overflow: auto;
    visibility: visible;
    opacity: 1;
    bottom: 0;
    z-index: 99;
}
.grid-hidden-container{
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s ease;
    position: absolute;
    bottom: 0;
    left: 0;
    bottom: -10%;
    height: 100%;
    width: 100%;
    padding: 32px;
    background: linear-gradient(94.03deg,#0f5c74 -123.48%,#0f5c74 -.15%,#000345 291.56%);
    color: #fff;
}
.grid-text{
    font-size: 0.9em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 24px;
    position: absolute;
    top: 20rem;
    color: white;
    opacity: 72%;
    padding: 5px 15px;
}
.grid.case-studies, .right.case-studies, .left.case-studies{
    padding:0 70px;
    .portfolio-item-container{
        padding: 0 10px;
    }
    @media only screen and (max-width: 768px) {
        padding:0;
        }
}