// glossary listing //

.glossary{
    background-color: #f3f4f6;
    .glossary-inner{
        .fpBGKh{
            border-radius: 1rem;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
            .huezRP{
                width: 100%;
                background-color: #f5f6fd;
                border-radius: 1rem;
            }
            .kGckkd{
                .mHisf{
                    background: #f5f6fd;
                    .rdt_TableCol{
                        background: linear-gradient(122.65deg, #0f5c74 -24.49%, rgba(0, 3, 69, 0.99) 136.4%) !important;
                        color: #fff;
                    }
                    .hWnSMz{
                        border: 1px solid #f5f6fd;
                    }
                }
            }
            .eXWrwD{
                .dCtngh{
                    background: none;
                    &:hover {
                        background-color: #efefef;
                        border-radius: 8px;
                      }
                    .rdt_TableCell{
                        background-color: rgba(255,255,255,0.2);
                        color: #000;
                        // border: none;
                        border-bottom-width: 1px;
                        border-bottom-color: rgba(0, 0, 0, .12);
                        border-bottom-style: solid;
                        a{
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .glossary-filter{
            .glossary-tab{
                cursor: pointer;
                width: calc(6.66% - 10px);
                @media only screen and (max-width: 820px) {
                    width: calc(10% - 10px);
                }
                @media only screen and (max-width: 480px) {
                    width: calc(20% - 10px);
                }
                &:hover{
                    background: linear-gradient(122.65deg, #0f5c74 -24.49%, rgba(0, 3, 69, 0.99) 136.4%);
                    button{
                        color: #fff;
                    }
                    span{
                        color: #fff;
                    }
                }
            }
        }
        .view-all{
            button{
                &:hover{
                    background: linear-gradient(122.65deg, #0f5c74 -24.49%, rgba(0, 3, 69, 0.99) 136.4%);
                    // background: #0071b3;
                    button{
                        color: #fff;
                    }
                    span{
                        color: #fff;
                    }
                }
            }
        }
    }
}