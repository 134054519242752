.testimonial-sec{
    .slick-slider{
        .slick-arrow {
            display: none !important;
        }
    }
    .testimonial{
        position: relative;
        width: 100%;
        &::after{
            transform: rotate(45deg);
            background: white;
            width: 30px;
            height: 30px;
            content: "";
            position: absolute;
            top: calc(100% - 15px);
            left: 50%;
            margin-left: -20px;
            border-radius: 0 0px 4px 0;
            z-index: -1;
        }
        .author-info-outer {
            position: absolute;
        }
    }
}

.expanded-list-container{
    li{
        background-image: url('../images/popup-coma.png');
        background-repeat: no-repeat;
        background-position-x: calc(100% - 20px);
        background-position-y: 20px;
        
        .listing-content{
            :last-child{
                display: contents;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
   .listing-coma-image{
    display: none;
   }
   .testimonial-modal{
    width:85%;
   }
}