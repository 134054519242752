.question-section,
.score-section {
    // text-align: center;
}

.question-section {
    // background: linear-gradient(94.03deg, #0f5c74 -123.48%, #0f5c74 -0.15%, #000345 291.56%);
    // background-color: #0082a4;
    text-align: center;
    position: relative;
    &::before{
        content: '';
        position: absolute;
        background-color: #ffcf1c;
        height:5px;
        width: 100%;
        top: 48%;
        left: -38px;
        z-index: -1;
        @media only screen and (max-width: 600px) {
            display: none;
            }
       
    }
    &::after{
        content: '';
        position: absolute;
        background-color: #ffcf1c;
        height:5px;
        width: 100%;
        top: 48%;
        right: -39px;
        z-index: -1;
        @media only screen and (max-width: 600px) {
            display: none;
            }
        
    }
}

.question-section,
.answer-section {
    // box-shadow: 15px 15px 30px #bebebe,
        // -15px -15px 30px #ffffff;
    transition: 0.2s ease-in-out;

    // &:hover {
    //     cursor: pointer;
    //     box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    // }
    h2{
        font-size: 22px;
        font-weight: 600;
        text-transform: capitalize;
        display: inline-block;
        position: relative;
        background-color: #ffcf1c;
        padding:20px;
        color:#013164;
        @media only screen and (max-width: 600px) {
            font-size: 14px;
            }
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            right: -71px; 
            transform: translateY(-50%);
            border: solid transparent;
            border-left-color: #ffcf1c;
            border-width: 36px;
            pointer-events: none;
        }
        &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: -71px; 
            transform: translateY(-50%);
            border: solid transparent;
            border-right-color: #ffcf1c;
            border-width: 36px;
            pointer-events: none;
        }
    }
}


.result-section button {
    display: block;
    margin: 5px;
    cursor: pointer;
    // border: 1px solid #ddd;
    flex: auto;
    border-radius: 27px;
    padding: 15px;
    background-color: #eeecf4;
    color: #b3b0be;
    font-weight: 600;
}

.question-text {
    margin-top: 20px;
    font-size: 24px;
    font-weight: bold;
}

.quiz-options {
    flex: auto;
}

.answer-section {
    display: flex;
    flex-wrap: wrap;
    padding-top:70px;
    gap:40px;
    justify-content: space-around;
    align-items: center;
    @media only screen and (max-width: 600px) {
        justify-content: space-between;
        }
    .btn-wrapper{
        width: 35%;
        position: relative;
    &::before{
        content: '';
        position: absolute;
        background-color: #ffcf1c;
        height:5px;
        width: 100%;
        top: 48%;
        left: -99px;
        z-index: -1;
        @media only screen and (max-width: 600px) {
            display: none;
            }
    }
    &::after{
        content: '';
        position: absolute;
        background-color: #ffcf1c;
        height:5px;
        width: 100%;
        top: 48%;
        right: -99px;
        z-index: -1;
        @media only screen and (max-width: 600px) {
            display: none;
            }
        
    }
    }
    button {
        display: block;
        cursor: pointer;
        // border: 1px solid #ddd;
        flex: auto;
        // border-radius: 27px;
        padding: 15px;
        color: #013164;
        font-weight: 600;
        width:100%;
        background-color: #ffcf1c;
        position: relative;
        text-wrap: wrap;
        word-break: break-all;
        
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            right: -54px; 
            transform: translateY(-50%);
            border: solid transparent;
            border-left-color: #ffcf1c;
            border-width: 28px;
            pointer-events: none;
            
        }
        &::before{
            content: "";
            position: absolute;
            top: 50%;
            left: -54px;
            transform: translateY(-50%);
            border: solid transparent;
            border-right-color: #ffcf1c;
            border-width: 28px;
            pointer-events: none;
            
        }

        &:hover {
            text-transform: uppercase;
            color: #ffffff;
        }
    }
}
.next-button-container{
    text-align: center;
    margin-top: 30px;
    .next-btn{
        background: linear-gradient(94.03deg, #0f5c74 -123.48%, #0f5c74 -0.15%, #000345 291.56%);
        color: white;
        padding: 1rem 3rem;
        border-radius: 0.25rem;
    }
}

.result-section {
    margin: 20px 0;
    display: flex;
    flex-wrap: wrap;
}

.result .question-text {
    font-size: 16px;
    font-weight: 500;
}


.navigation-buttons {
    text-align: center;
    margin-top: 30px;
}

button:disabled {
    cursor: initial;
}

.correctans,
.ansright {
    // background-color: #28a745;
    // border-color: #28a745;
    
    position: relative;
    button{
        background-color: green;
        text-transform: uppercase;
        color: #ffffff;
        &::after{
            border-left-color:green;
        }
        &::before{
            border-right-color:green;
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: calc(50% - 10px);
        right: -4px;
        width: 20px;
        height: 20px;
        background-size: 20px;
        background-image: url(../images/checked.png);
        @media only screen and (max-width: 600px) {
            display: none;
            }
    }
}

.answrong {
    // background-color: #db150b;
    // border-color: #db150b;
    
    position: relative;
    button{
        background-color: #fd7878;
        text-transform: uppercase;
            color: #ffffff;
        &::after{
            border-left-color:#fd7878;
        }
        &::before{
            border-right-color:#fd7878;
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: calc(50% - 10px);
        right: -4px;
        width: 20px;
        height: 20px;
        background-size: 20px;
        background-image: url(../images/cancel.png);
        @media only screen and (max-width: 600px) {
            display: none;
            }
    }
}

.error-message {
    color: #df0f0f;
    position: absolute;
    margin-top: -18px;
}

// new popup quiz code 

// .Modal {
//     position: fixed;
//     max-height: calc(100vh - 110px);
//     top: 50%;
//     left: 50%;
//     right: auto;
//     bottom: auto;
//     transform: translate(-50%, -50%);
//     background: white;
//     padding: 2rem;
//     z-index: 1000;
//     border-radius: 10px;
//     box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
//     width: 80%;
//     max-width: 950px;
//     overflow: auto;
// }

// .Overlay {
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: rgba(0, 0, 0, 0.75);
//     z-index: 999;
// }

// .close-button {
//     position: absolute;
//     top: -18px;
//     right: 6px;
//     background: transparent;
//     border: none;
//     font-size: 50px;
//     cursor: pointer;
// }

.score-section{
    text-align: center;
    h2{
        font-size: 28px;
        font-weight: 600;
        text-transform: capitalize;
        padding:20px;
        color:#013164;
    }
    p{
        font-size: 28px;
        font-weight: 600;
        text-transform: capitalize;
        padding:20px;
        color:#013164;
        .score-1{
            color: darkorange;
        }
        .score-2{
            color: forestgreen;
        }
    }
    .start-again-btn{
        
            background: linear-gradient(94.03deg, #0f5c74 -123.48%, #0f5c74 -0.15%, #000345 291.56%);
            color: white;
            padding: 1rem 3rem;
            border-radius: 0.25rem;
            margin-top: 50px;
        
    }
}

// new category quiz popup 

.category-cards {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    column-gap: 2%;
    row-gap: 30px;
}

.card {
    width: 32%;
    // height: 200px;
    perspective: 1000px;
    cursor: pointer;
    @media only screen and (max-width: 600px) {
        width:100%;
        }
}

.card-content {
    width: 100%;
    height: 250px;
    text-align: center;
    position: relative;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    background-color: #f7fbff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.card:hover .card-content {
    transform: rotateY(180deg);
}

.card-front,
.card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    // border: 1px solid #ccc;
    border-radius: 10px;

}

.card-front {
    background-color: #f7fbff;

    // background-image: url('https://t4.ftcdn.net/jpg/02/86/76/77/360_F_286767786_boXM75PDLYIsYWzabZ3fKcM3esv50TNS.jpg');
    span {
        position: absolute;
        overflow: hidden;
        width: 150px;
        height: 150px;
        top: -10px;
        left: -10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: 'Quiz';
            position: absolute;
            width: 150%;
            height: 40px;
            background-image: linear-gradient(45deg, #ff6547 0%, #ffb144 51%, #ff7053 100%);
            transform: rotate(-45deg) translateY(-20px);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: 600;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
        }

        &::after {
            content: '';
            position: absolute;
            width: 10px;
            bottom: 0;
            left: 0;
            height: 10px;
            z-index: -1;
            box-shadow: 140px -140px #cc3f47;
            background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
        }
    }

    img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}

.card-back {
    transform: rotateY(180deg);
    // box-shadow: 0 8px 50px #23232333;

    span {
        position: absolute;
        overflow: hidden;
        width: 150px;
        height: 150px;
        top: -10px;
        left: -10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: 'Start Quiz';
            position: absolute;
            width: 150%;
            height: 40px;
            background-image: linear-gradient(45deg, #ff6547 0%, #ffb144 51%, #ff7053 100%);
            transform: rotate(-45deg) translateY(-20px);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-weight: 600;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.23);
        }

        &::after {
            content: '';
            position: absolute;
            width: 10px;
            bottom: 0;
            left: 0;
            height: 10px;
            z-index: -1;
            box-shadow: 140px -140px #cc3f47;
            background-image: linear-gradient(45deg, #FF512F 0%, #F09819 51%, #FF512F 100%);
        }
    }
}

.start-quiz-btn {
    // background-color: #fff;
    // color: #000;
    background: linear-gradient(122.65deg, #0f5c74 -24.49%, rgba(0, 3, 69, 0.99) 136.4%);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.Modal {
    position: fixed;
    height: 547px;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 80px 40px;
    z-index: 1000;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    width: 75%;
    
    overflow: auto;
    background-image: url('../images/quiz-bg.png') ;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 999;
}

.close-button {
    position: absolute;
    top: 29px;
    right: 6px;
    color: white;
    background: #022062;
    border-radius: 50%;
    padding: 0px 14px;
    border: none;
    font-size: 30px;
    cursor: pointer;
}

.correct {
    background-color: green;
    color: white;
    border-color: green;
}

.incorrect {
    background-color: red;
    color: white;
    border-color: red;
}

.show-correct {
    background-color: green;
    color: white;
    border-color: green;
}

.correct-icon, .incorrect-icon {
    margin-left: 8px; /* Spacing between text and icon */
}
  