.login-banner {
    background: linear-gradient(122.65deg, #0f5c74 -24.49%, rgba(0, 3, 69, .91) 136.4%);
    background-color: #0f5c74;
}

.or {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 230px;
        height: 1px;
        background-color: #a1979761;
    }

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 230px;
        height: 1px;
        background-color: #a1979761;
    }
}

.soc-btn {
    width: 100%;
    padding: 12px;
    border: 1px solid #dadada;
    border-radius: 4px;
    margin: 5px 0;
    opacity: .85;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    text-decoration: none;
    color: #000;
}

.user-profile-wrapper{
    // background-color: #f3f4f6;
    .edit-form{
        border-radius: 1rem;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2)
    }
    
    .edit-btn{
        background: linear-gradient(122.65deg,#0f5c74 -24.49%,rgba(0,3,69,.99) 136.4%);
    }
}

.login-container {
    z-index:2;
}


// Edit profile //

.editprofile-container{
    padding-top: 20px;
    .editprofile-img{
        position: absolute;
        left: 50%;
    &::before{
        content: "";
        background: url(../images/cam_icon.svg) no-repeat center center;
        position: absolute;
        background-size: 25px;
        background-color: #ffffff;
        // background-color: rgb(219 226 254 / var(--tw-bg-opacity));
        cursor: pointer;
        width: 35px;
        height: 35px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 1);
        // box-shadow: 0 3px 10px rgba(3, 69, 102, 1);
        border-radius: 35px;
        top: 51px;
        right: 31px;
        transform: translate(100%, -100%);
        top: 0;
        right: 20px;
    }
    &:hover::before{
        opacity: 0.5;
        filter: brightness(0.7); 
    }
}
    input{
        width: 35%;
        padding-right: 10px;
    }
    button{
        background: linear-gradient(122.65deg, #0f5c74 -24.49%, rgba(0, 3, 69, 0.99) 136.4%);
        color: #ffffff;
        padding: 5px 20px;
        border-radius: 6px;
    }
}

.user-container{
    border-radius: 1rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    .edit-icon{
        img{
            &:hover{
                opacity: 0.5;
                filter: brightness(0.7);
            }
        }
    }
}