// Cloud page //

.page-title + div{
    
    h2{
        font-size: 40px;
        margin-bottom: 20px;
        text-align: center;
        font-weight: 300;
        letter-spacing: 0.4px;
    }
    p{
        padding-bottom: 7px;
        font-size: 20px;
        font-weight: 400;
        color: #555;
        line-height: 36px;
    }
    .product-detail{
        display: flex;
        flex-wrap: wrap;
        padding: 40px 0px 50px 0px;
        .producr-detail-item{
            padding: 10px;
            width: 33.33333333%;
            color: #595959;
            @media only screen and (max-width: 865px) {
                width: 100%;
            }
            .dpe-gamut{
                background: #ffffff;
                padding: 30px;
                height: 100%;
                box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
                position: relative;
                &:hover{
                    background: #12519c;
                    .title, .description .subtitle, .description .with-bullets ul li{
                        color: #ffffff;
                    }
                    .description{
                        .with-bullets{
                            ul{
                                li{
                                    &::after{
                                        border: 1px solid #ffffff;
                                    }
                                }
                            }
                        }
                    }
                }
                .title{
                    margin: 22px 0 8px;
                    color: #333333;
                    font-size: 18px;
                    line-height: 30px;
                    font-weight: 600;
                }
                .description{
                    .subtitle{
                        font-size: 16px;
                        color: #595959;
                        padding: 0 0 18px;
                        font-weight: 600;
                        line-height: 30px;
                    }
                    .with-bullets{
                        margin-top: 6px;
                        ul{
                            display: flex;
                            flex-wrap: wrap;
                            li{
                                width: 100%;
                                padding: 0 0 0 24px;
                                font-size: 15px;
                                font-weight: 400;
                                color: #555;
                                position: relative;
                                line-height: 24px;
                                margin-bottom: 14px;
                                &::after{
                                    content: "" !important;
                                    width: 9px;
                                    height: 9px;
                                    position: absolute;
                                    left: 4px;
                                    background: transparent;
                                    border: 1px solid #B6266C;
                                    border-radius: 6px;
                                    top: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Devops page //

.devops{
    .devops-inner{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        article{
            width:21%;
            @media only screen and (max-width: 865px) {
                 width: 100%;
                 display: flex;
                 align-items: center;
                 justify-content: center;
                 
                }
            img{
                width: 255px;
            }
        }
        .devops-text{
            width:75%;
            color: #22375e;
          font-weight: 300;
          font-size: 1.4em;
          h3{
            font-size: 26px;
            font-weight: 600;
        
          }
          @media only screen and (max-width: 865px) {
            width: 100%;
            
           }
        }
        &:nth-child(even){
            flex-direction: row-reverse;
        }
    }
    
}

// Idanim page //

.idanim{
    p{
        font-size: 20px;
        line-height: 32px;
        color: #767676;
        text-align: justify;
        margin: 10px 0;
    }
    ul{
        li{
            width: 100%;
            padding: 0 0 0 24px;
            font-size: 16px;
            font-weight: 400;
            color: #555;
            position: relative;
            line-height: 25px;
            margin-bottom: 14px;
            list-style-type: none;
            &::before{
                content: "";
                width: 9px;
                height: 9px;
                position: absolute;
                left: 4px;
                background: transparent;
                border: 1px solid #B6266C;
                border-radius: 6px;
                top: 9px;
            }
        }
    }
}

// Disclosure page //

.disclosure{
    margin-top: 6px;
    p{
        font-size: 18px !important;
        line-height: 28px !important;
    }
    h3{
        font-size: 40px;
        font-weight: 300;
        color: #000000;
        margin-bottom: 15px;
        margin-top: 25px;
        line-height: 55px;
        letter-spacing: 0.4px;
    }
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            width: 100%;
            padding: 0 0 0 24px;
            font-size: 15px;
            font-weight: 400;
            color: #555;
            position: relative;
            line-height: 24px;
            margin-bottom: 14px;
            &::after{
                content: "" !important;
                width: 9px;
                height: 9px;
                position: absolute;
                left: 4px;
                background: transparent;
                border: 1px solid #B6266C;
                border-radius: 6px;
                top: 8px;
            }
        }
    }
}

// basic page //

.basic-page{
    padding-bottom: 48px;
    @media only screen and (max-width: 600px) {
        padding-bottom: 30px;
    }
    img{
    width: 100%;
    margin-bottom: 24px;
    }   
    p,ul,h3,h4 {
        padding-bottom: 15px!important;
        font-size: 18px!important;
        line-height: 28px!important;
    }
    p,ul{
        color: #4f4f4f!important;
    }

    h3,h4 {
        font-size: 20px;
        font-weight: 600;
        color: #2C2C2C;
    }
    ul{
    list-style: inside;
    li{
        margin-bottom: 16px;
    }
    }
}

// data & digital //

.data, .digital{
    ul{
        li{
            width: 100%;
            padding: 0 0 0 24px;
            font-size: 16px;
            font-weight: 400;
            color: #555;
            position: relative;
            line-height: 25px;
            margin-bottom: 14px;
            list-style-type: none;
            &::before{
                content: "";
                width: 9px;
                height: 9px;
                position: absolute;
                left: 4px;
                background: transparent;
                border: 1px solid #B6266C;
                border-radius: 6px;
                top: 9px;
            }
        }
    }
}