header {
    background: linear-gradient(122.65deg, #0f5c74 -24.49%, rgba(0, 3, 69, .99) 136.4%);

    li {
        @media only screen and (max-width: 1023px) {

            width: 100%;
        }
    }

    // .newi{
    //     position: relative;
    //     &::after{
    //         content: "";
    //         height: 10px;
    //         width: 10px;
    //         background-color: #005490;
    //         position: absolute;
    //         border-radius: 50%;
    //         margin-left: 10px;
    //     }
    // }
}

#mega-menu-full-dropdown {
    display: none;
}

// #mega-menu-full > ul > li{
//     &:hover  #mega-menu-full-dropdown{
//         display: block;
//     }
// }

.state-dropdown {
    background: url('../images/location.png') no-repeat 0/12px auto;
    padding: 0 0px 0 12px;
    cursor: pointer;

    [class$="control"] {
        background: transparent;
        border: none;
        box-shadow: none;
        cursor: pointer;

        [class$="singleValue"] {
            color: white;
        }

        [class$="Svg"] {
            fill: white;
        }

        [class$="indicatorSeparator"] {
            display: none;
        }
    }

    [class$="menu"] {
        ::-webkit-scrollbar {
            background-color: #cdd4ea;
            border-radius: 8px;
            height: 4px;
            width: 4px;
        }

        ::-webkit-scrollbar-thumb {
            background-clip: border-box;
            background-color: #8f99bd;
            border-radius: 8px;
        }

        width:260px;
        right:2%;

        @media only screen and (max-width: 480px) {
            width: 100%;
            
        }

        [class$="MenuList"] {
            padding: 10px;
            box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

            [class$="option"] {
                color: black;
                background-color: #fff;
                margin-bottom: 5px;
                cursor:pointer;

                // border-bottom: 1px solid #d9d9d9;
                &:active {
                    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                }

                &:hover {
                    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                    background-color: #efefef;
                    border-radius: 6px;
                }
            }

            [aria-selected="true"] {
                box-shadow: 0 3px 10px rgba(205, 232, 235, 1);
                background-color: #cde8eb;
                border-radius: 6px;
            }
        }
    }


}


#language-box {
    display: none;

    @media only screen and (max-width: 768px) {
        left: 0 !important;
    }
}

.language-dropdown-container:hover #language-box {
    display: block;
}

.login-container {
    display: none;
}

.login-info:hover .login-container {
    display: block;
}

.login-info {
    min-width: 146px;
    .login-container {

        li,
        .sign-out {
            &:hover {
                box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
                background-color: #efefef;
                border-radius: 6px;
            }

            [aria-current="page"] {
                box-shadow: 0 3px 10px rgba(205, 232, 235, 1);
                background-color: #cde8eb;
                border-radius: 6px;
            }
        }

        .sign-out {
            [aria-current="page"] {
                background-color: transparent !important;
                box-shadow: none;
            }
        }

        @media only screen and (max-width: 480px) {
            width: 100%;
        }
    }
}

.hamburger-menu {
    background: none !important;
    outline: none !important;
}
nav{
    .nav-link{
        img{
            @media only screen and (max-width:480px ) {
                width:86px !important;
            }
        }
    }
    
}
