
.banner-wrapper{
    background: linear-gradient(122.65deg,#0f5c74 -24.49%,rgba(0,3,69,.91) 136.4%);

    .right{
        position: relative;
        &::before{
            content: "";
            background-image: url('../images/circle.png');
            background-repeat: no-repeat;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            left: 0;
            top: 6%;
            opacity: 0.4;
        }
    }
    .slick-dots{
        bottom:15px;
        width:auto;
        left:50%;
        transform:translateX(-50%);
       li {
            button{
                &::before{
                   font-size: 15px;
                    @media only screen and (max-width: 768px) {
                        color:white;
                    }
                }
            }
            
        }
        .slick-active{
            color:White;
        }
    
    }
}

.view-all-link{
    a{
        background: linear-gradient(94.03deg,#0f5c74 -123.48%,#0f5c74 -.15%,#000345 291.56%);
    }
}

.portfolio-section{
    max-width:100%;
    overflow:hidden;
    .slick-list:before {
        content: "";
        width: 90px;
        height: 100%;
        position: absolute;
        z-index: 3;
        left: 0;
        top: 0;
        background: white;
        opacity: 0.55;
        @media only screen and (max-width: 480px) {
            display: none;
        }
      }
      .slick-list:after {
        content: "";
        width: 90px;
        height: 100%;
        position: absolute;
        z-index: 3;
        right: 0;
        top: 0;
        background: white;
        opacity: 0.32;
        @media only screen and (max-width: 480px) {
            display: none;
        }
      }
    .slick-prev,.slick-next{
        cursor: pointer;
        margin: 0;
        position: absolute;
        text-indent: -999px;
        overflow: hidden;
        width: 34px!important;
        height: 34px!important;
        opacity: 1;
        background-repeat: no-repeat;
        background-size: 6.91px 12px;
        background-position: 50%;
        border: .5px solid #034d82;
        box-shadow: 0 4px 4px rgba(0,0,0,.25);
        top: 35%;
        transform: translateY(-50%);
        z-index: 999;
        border-radius: 50%;
        @media only screen and (max-width: 480px) {
            display: none;
            border: none;
            width: 0;
            height: 0;
            box-shadow: none;
        }
    }
    .slick-next{
        background-image: url("../images/next.png");
        right:81px;
        @media only screen and (max-width: 480px) {
            display: none;
            background-image: none;
        }
    }
    .slick-prev{
        background-image: url("../images/prev.png");
        left:83px;
        @media only screen and (max-width: 480px) {
            display: none;
            background-image: none;
        }
    }
}

.testimonial-modal, .resume-popup {
    width: 40%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1260;
    padding: 0;
    border: none!important;
    background: #fff;
    max-width: 1100px!important;
    max-height: 100%;
    border-radius: 20px;
}
.resume-popup{
    max-width: 700px!important;
    width: 46%;
    border-radius: 0;
    max-height: calc(100vh - 150px) !important;
    border-radius: 1rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    @media only screen and (max-width: 600px) {
         width: 80%; 
      }
    @media only screen and (max-width: 820px) {
    width: 75%; 
    }
    .close-modal{
        &::before{
            content: '';
            position: absolute;
            width: 22px;
            height: 22px;
            // background: url(../images/close.png) no-repeat;
            background-size: 22px;
            right: 20px;
            top: 17px;
            background-color: transparent;
        }
    }
  .resume-inner{
    max-height: calc(100vh - 110px);
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    @media only screen and (max-width: 480px) {
        max-height: calc(100vh - 265px);
    }
  }
}

.ReactModal__Overlay {
    background: rgba(0, 0, 0, 0.8) !important;
    z-index: 9999;
}

// quiz section //
.qz-left{
    h3{
        font-weight: 700;
        font-size: 36px;
        color: #034d82 !important;
        @media only screen and (max-width: 480px) {
            font-size: 32px;
        }
    }
    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: #4f4f4f !important;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    ul {
        padding-bottom: 20px;
        padding-left: 10px;
        li {
            position: relative;
            font-weight: 400;
            font-size: 16px;
            line-height: 32px;
            letter-spacing: .3px;
            color: #4f4f4f;
            padding-left: 20px;
            &::before{
                content: '';
                position: absolute;
                width: 16px;
                height: 16px;
                top: 8px;
                left: -10px;
                background: url(../images/tick.png) no-repeat;
                padding-right: 20px;
            }
        }
      }

    .arrow{
        padding-right: 74px;
        &::before{
            content: '';
            background: url(../images/arrowbtn.png) no-repeat;
        }
    }
}

// blogs section //
    .blogs-bottom-sec{
        .reads{
            &::after{
                content: '';
                background: url(../images/bg-arrow.png) no-repeat;
                top: 12px;
            }
        }
}
// .portfolio-section{
//     .portfolio-header{
//         margin-top: 65px;
//     }
//     .leading-64px{
//         margin-bottom: 50px;
//     }
//     .py-4{
//         padding-left: 60px;
//         padding-right: 60px;
//     }
// }


.color-button{
    background-color: #c8e5ed; 
    display: block;
    position: fixed;
    top: 50%;
    right: 10px;
    z-index: 2147483647;
    font-weight: 400;
    font-size: 14px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.28);
    transition: all 200ms ease-in-out;
    transform: none;
    opacity: 1;
    @media only screen and (max-width: 480px) {
        top: 95px;
    }
    .color-change-logo{
        height: 60px;
        width: 60px;
        background-image: url(../images/Settings-icon.png);
        background-size: 40px 40px;
        background-repeat: no-repeat;
        background-position: center center;
    }
    .color-change-inner{
        position: absolute;
        top: -4px;
        left: -2px;
        width: 23px;
        height: 23px;
        line-height: 19px;
        border-radius: 50%;
        font-size: 13px;
        font-weight: bold;
        border-width: 2px;
        color: #fff;
        text-align: center;
        border-style: solid;
        border-color: #fff;
        background: #0C9735;
        box-sizing: border-box;
        display: none;
    }
}

.slide.open {
    right: 100px;
    background-color: #f4f5f6;
    padding: 20px;
    border-radius: 1rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    z-index: 2147483647;
    overflow-y: auto;
    @media only screen and (max-width: 480px) {
        right: 72px;
    }
}
.slide {
    position: fixed;
    top: 130px;
    right: -100%; 
    width: 300px; 
    height: 80%;
    background-color: #fff;
    transition: right 0.5s ease; 
}

.dashboard{
    .edit-btn{
        background: linear-gradient(122.65deg, #0f5c74 -24.49%, rgba(0, 3, 69, 0.99) 136.4%);
    }
     ::-webkit-scrollbar {
        background-color: #cdd4ea;
        border-radius: 8px;
        height: 4px;
        width: 4px;
    }
     ::-webkit-scrollbar-thumb {
        background-clip: border-box;
        background-color: #8f99bd;
        border-radius: 8px;
    }
}